<template>
  <div>
    <register-of-facilities></register-of-facilities>
  </div>
</template>

<script>
import registerOfFacilities from "@/components/registerOfFacilities";
export default {
  name: "registerOfFacilitiesView",
  components: { registerOfFacilities },
};
</script>

<style scoped></style>