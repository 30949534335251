<template>
  <main>
    <div class="main-container">
      <div>
        <div class="main-container-select">
          <div>Выберите необходимый реестр</div>
          <select name="" id="">
            <option>Реестр объектов по использованию отходов</option>
            <option>
              Реестр объектов хранения, захоронения и обезвреживания отходов
              (обезвреживание)
            </option>
            <option>
              Реестр объектов хранения, захоронения и обезвреживания отходов
              (хранение, захоронение)
            </option>
          </select>
        </div>
      </div>
      <div class="main-container-search">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-select
              clearable
              @change="search.name=wasteNames.find(obj => obj.id === search.code.id);PostRegisterOfFacilitis();"
              class="searchPole"
              v-model="search.code"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Код отхода"
              :remote-method="searchCode"
            >
              <el-option
                v-for="item in ss01CodeList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-select
              clearable
              @change="search.code=ss01CodeList.find(obj => obj.id === search.name.id);PostRegisterOfFacilitis();"
              class="searchPole"
              v-model="search.name"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Наименование"
              :remote-method="searchWasteName"
            >
              <el-option
                v-for="item in wasteNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <div class="main-container-checkbox">
          <div class="main-container-checkbox-inner">
            <input
              type="checkbox"
              v-model="search.alien"
              @change="PostRegisterOfFacilitis()"
            />
            <label for="checkbox">Принимают от других</label>
          </div>
          <div class="main-container-checkbox-inner">
            <input
              type="checkbox"
              v-model="search.own"
              @change="PostRegisterOfFacilitis()"
            />
            <label for="checkbox">Используют собственные</label>
          </div>

          <!-- <p>{{ checked ? "yes" : "no" }}</p>"-->
        </div>
      </div>
    </div>
    <div class="main-container-second">
      <el-col :span="24">
        <div class="main-container-second-object">
          Cуществующие объекты по использованию отхода
        </div>
        <div class="main-container-list">
          <div
            class="main-container-list-inner"
            :key="key"
            v-for="(registerObject, key) in register"
          >
            <img src="../assets/MarkerSVG.svg" alt="marker" />
            <p v-bind:style="{ backgroundColor: activeEl==key ? '#7ac750' : '#ffffff'}" @click="showModal(registerObject,key)">
              
              {{ registerObject.objectname }}</p>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="main-container-second-map-descr">
          Карта существующих объектов по использованию отхода
        </div>
        <div class="main-container-second-map">
          <GmapMap
            :center="zenter"
            :zoom="7"
            map-type-id="terrain"
            style="width: 1100px; height: 1100px"
            @click="visible=false; marker= {lat: 1,lng: 1,}"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in register"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="showModal(m,index)"
              
            />
            
            <gmap-custom-marker :marker="marker">
              
              <div
                class="card"
                v-if="visible"
              >
                <div class="card-name">{{ markerName }}</div>
                <div class="card-adress">{{ markerAdress }}</div>
                <div class="card-phone">{{ markerPhone }}</div>
              </div>
              <p style="text-align:center;margin-bottom: 0%;">
                <img src="../assets/MarkerVaste.svg" />
              </p>
            </gmap-custom-marker>
          </GmapMap>
        </div>
      </el-col>
    </div>
  </main>
</template>

<style scoped>
.main-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  flex-direction: column;
}
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
.main-container-select {
  color: #000;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 25px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
}
.main-container-search {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  height: 70px;
}
.main-container-search-input {
  display: flex;
  align-items: center;
  color: #a5a5a5;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
input[type="search"] {
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  border-radius: 2px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  border: 3px solid #7ac750;
  background: rgba(122, 199, 80, 0);
  width: 300px;
}
input[type="submit"] {
  height: 20px;
  border-radius: 2px;
  color: white;
  background-color: #41bf2d;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  cursor: pointer;
  border: 1px solid #7ac750;
}
select {
  color: white;
  background-color: #7ac750;
  font-size: 18px;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  height: 50px;
  border-radius: 10px;
  border: 3px solid #7ac750;
  cursor: pointer;
}
.main-container-checkbox-inner {
  display: flex;
}
.main-container-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 3px solid #7ac750;
  background: rgba(122, 199, 80, 0);
  border-radius: 5px;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
}
input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}
.main-container-second {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 10px;
  justify-items: center;
  /* align-items: center; */
  background: #f8f8f8;
  font-size: 18px;
}
.main-container-second-object {
  background: #7ac750;
  color: white;
  background-color: #7ac750;
  font-size: 18px;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  height: 50px;
  border-radius: 10px;
  border: 3px solid #7ac750;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-container-second-map-descr {

  width: 100%;
  color: #7ac750;
  background-color: white;;
  font-size: 18px;
  /* box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25); */
  height: 50px;
  border-radius: 10px;
  /* border: 3px solid #7ac750; */
  /* text-align: center; */
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-container-list {
  background: #f8f8f8;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.main-container-list p {
  color: #0e3232;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.main-container-list-inner {
  display: flex;
  padding: 5px;
  padding-left: 10px;
  align-items: center;
}
.main-container-list-inner img {
  width: 25px;
  height: 25px;
  /* background-color: #349324;
  border-radius: 50%; */
  margin-right: 10px;
}
.main-container-list-inner p {
  width: 80%;
}
.main-container-second-map {
  background: #ccf8b3;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.el-col-16 {
  width: 90%;
  box-shadow: 4px 4px 4px 4px rgba(19, 172, 53, 0.25);
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#subregions {
  fill: #eeeeee;
  stroke: #ffffff;
  stroke-width: 0.5;
}
#regions {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
}
circle {
  fill: #cccccc;
  stroke: none;
}

/* модалка на маркерах */
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #fafafa;
  padding: 15px;
  box-shadow: 3px 3px 3px grey;
  border: 1px solid #ccc;
  width: 150px;
  font-weight: bold;
}

.card-name {
  font-weight: bolder;
  padding-bottom: 5px;
  font-size: 12px;
}
.card-adress {
  padding-bottom: 5px;
  font-size: 10px;
}
.card-phone {
  padding-bottom: 5px;
  font-size: 10px;
}

.colored {
  background-color: #7ac750;
  border-radius: 10px;
  cursor: pointer;
}
</style>

<script>
import { AXIOS } from "@/AXIOS/http-common";

import { mapGetters } from "vuex";

import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  name: "App",
  data() {
    return {
      zenter:{ lat: 53.8845267, lng: 27.4282195 },
      wasteNames:[],
      ss01CodeList:[],
      activeEl:"",
      register: null,
      search: {
        code: "",
        name: "",
        alien: null,
        own: null,
      },
      visible: false,
      marker: {
        lat: 1,
        lng: 1,
      },
      markerName: "",
      markerAdress: "",
      list: [],
    };
  },
  methods: {
    filterChain(Maper) {
      let Map = Maper;
      let MapGroup = [];
      Map.forEach((element) => {
        let bol = true;
        MapGroup.forEach((searchElem) => {
          searchElem.objectaddress === element.objectaddress
          //searchElem.objectname + searchElem.ownername === element.objectname + element.ownername
            ? (bol = false)
            : "";
        });
        if (
          bol &&
          (this.search.code == "" ||
            this.search.code.name ==
              element.ss01WasteDictionaryDTO.wasteCode) &&
          (this.search.name == "" ||
            this.search.name.name ==
              element.ss01WasteDictionaryDTO.wasteName) &&
          (
            (
              (this.search.alien == null || this.search.alien == element.alien) &&
              (this.search.own == null || this.search.own == element.own)
            ) || 
            (this.search.alien== false && this.search.own == false))
        ) {
          MapGroup.push({
            doc: element.doc,
            objectaddress: element.objectaddress,
            objectname: element.objectname,
            objectnumberPhone: element.objectnumberPhone,
            ownername: element.ownername,
            owneraddress: element.owneraddress,
            ownernumberPhone: element.ownernumberPhone,
            position: { lat: element.lat, lng: element.lng },
            waste: [],
          });
        }
      });
      Map.forEach((element) => {
        MapGroup.forEach((searchElem) => {
          if (element.objectaddress === searchElem.objectaddress) {
            if (
              searchElem.waste.indexOf({
                alien: element.alien,
                own: element.own,
                ss01WasteDictionaryDTO: element.ss01WasteDictionaryDTO,
              }) == -1
            )
              searchElem.waste.push({
                alien: element.alien,
                own: element.own,
                ss01WasteDictionaryDTO: element.ss01WasteDictionaryDTO,
              });
          }
        });
      });
      return MapGroup;
    },

    /*async getRegisterOfFacilitis() {
      await AXIOS.get("register-of-facilities/get?page="+0+"&size="+100000).then((response) => {
        this.registerzero = response.data.content;
        this.register = this.filterChain(this.registerzero);
      });
    },*/
    async PostRegisterOfFacilitis() {
      await AXIOS.post("register-of-facilities/search",{alien:this.search.alien,own:this.search.own,code:this.search.code.name}).then((response) => {
        this.register = response.data;
        this.register.forEach(element => {
          element.position= { lat: element.lat, lng: element.lng };
          element.type=0;
        });
      });
    },

    async searchCode(query) {
      await this.$store.dispatch("getCodeList", query);
      this.ss01CodeList=this.ss01CodeList0;
      this.wasteNames=[];
      this.ss01CodeList0.forEach(ss01Code => {
        this.wasteNames.push({id:ss01Code.id,name:ss01Code.name2});
      });
    },

    async searchWasteName(query) {
      await this.$store.dispatch("getWasteNames", query);
      this.wasteNames=this.wasteNames0;
      this.ss01CodeList=[];
      this.ss01CodeList.forEach(wasteName => {
        this.wasteNames.push({id:wasteName.id,name:wasteName.name2});
      });
    },

    searchROF() {
      this.registerzero.forEach((registr, i) => {
        registr.waste.forEach((waste, j) => {
          let nuls = true;
          if (
            this.search.alien == null ? true : waste.alien == this.search.alien
          ) {
            if (this.search.own == null ? true : waste.own == this.search.own) {
              if (
                this.search.code == null || this.search.code.name === ""
                  ? true
                  : waste.ss01WasteDictionaryDTO.wasteCode ===
                    this.search.code.name
              ) {
                if (
                  this.search.name == null || this.search.name.name === ""
                    ? true
                    : waste.ss01WasteDictionaryDTO.wasteName ===
                      this.search.name.name
                )
                  nuls = false;
              }
            }
          }
          if (nuls) this.register[i].waste[j];
        });
      });
    },
    showModal(markerMap, index) {
      this.activeEl = index;
      this.visible = false;
      this.zenter=markerMap.position;
      this.marker = markerMap.position;
      this.markerAdress = markerMap.objectaddress;
      this.markerName = markerMap.ownername;
      this.markerPhone = markerMap.ownernumberPhone;
      // this.visible = !this.visible;
      this.visible = true;
    },
    closeModal() {
      // this.marker = markerMap.position;
      // this.markerAdress = markerMap.objectaddress;
      // this.markerName = markerMap.objectname;
      // this.visible = !this.visible;
      this.visible = false;
    },
    colored(event) {
      var list = document.querySelectorAll(".main-container-list-inner p");

      console.log(event.target); // пункт списка
      for (let elem of list) {
        elem.addEventListener("click", function () {
          this.classList.toggle("colored"); // добавляем абзацу класс
        });
      }
      this.list = list;
      // event.target.classList.add("colored");
    },
  },

  computed: {
    ...mapGetters({
      ss01CodeList0: "GETSSS01CODELIST",
      wasteNames0: "GETWASTENAMES",
      foundedWaste: "GETSS01DICTIONARY",
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
    }),
  },

  async mounted() {
    //await this.PostRegisterOfFacilitis();
  },
};
</script>
